<template>
  <div>
    <div v-if="!isLoading">
      <div v-if="$route.path == '/ho-tro/cau-hoi-thuong-gap'">
        <h4 class="mb-4">Câu hỏi thường gặp</h4>
        <div class="faqArticleList" v-for="(item, index) in listQuestionFAQ" :key="index">
          <ArticleFAQList
            v-if="item"
            :data="item"
            :index="index"
            :activedFAQ="activedFAQ"
            @handlerUpdateFAQ="handlerUpdateFAQ"
          />
        </div>
      </div>
      <div v-else>
        <div class="mb-4">
          <h4>{{ title }}</h4>
          <div v-if="imageUrl" class="d-flex justify-content-center">
            <img :src="imageUrl" width="160" height="126" class="img-109-86-mb" />
          </div>
        </div>
        <b-tabs v-if="isTab" class="articleContentTab" fill>
          <b-tab title="Luật chơi" :active="isActiveGameRule">
            <div v-if="content && content.length > 0" class="container px-lg-3 px-0 pt-4">
              <div class="fullDescription" v-html="content"></div>
            </div>
          </b-tab>
          <b-tab title="Cách chơi" :active="!isActiveGameRule">
            <div v-if="content && content.length > 0" class="container px-lg-3 px-0 py-4">
              <div class="fullDescription" v-html="content2"></div>
            </div>
          </b-tab>
        </b-tabs>
        <div v-else>
          <div v-if="content && content.length > 0" class="container py-md-0 py-3 px-0">
            <div class="fullDescription" v-html="content"></div>
          </div>
          <div v-if="content2 && content2.length > 0" class="container px-0">
            <div class="fullDescription" v-html="content2"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center h-100">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>
<script>
import ArticleFAQList from '../../../components/Article/FAQ/ArticleFAQList';
export default {
  components: { ArticleFAQList },
  data() {
    return {
      content: `
    <style type="text/css">.vl-content h3, .vl-content h4 {
      font-size: 18px;
      color: #666;
      font-weight: 400;
      padding-top: 1rem; }
    .vl-content h4 {
      font-size: 15px;
      padding: 0; }
    .vl-content .alert-warning {
      font-size: 13px;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 2rem; }
    .vl-content section.prizes {
      margin-bottom: 2rem; }
      .vl-content section.prizes em {
        text-align: right;
        display: block; }
      .vl-content section.prizes p {
        margin-bottom: .5rem; }
    .vl-content table {
      width: 100%; }
      .vl-content table th, .vl-content table td {
        text-align: center;
        padding: 7px;
        border-width: 1px;
        border-style: solid; }
        .vl-content table th:last-child, .vl-content table td:last-child {
          border-right: none; }
      .vl-content table th {
        color: #ffffff; }
      .vl-content table.keno {
        border: #F15922 1px solid; }
        .vl-content table.keno th, .vl-content table.keno td {
          border-color: #F15922;
          border-right-color: #f7a182; }
        .vl-content table.keno th {
          background-color: #F15922; }
    .vl-content .keno-bosung table.keno th:nth-child(2){
        width: 30%;
    }
    </style>
    <div class="vl-content">
    <h3>Giới thiệu</h3>

    <p>Xổ số truyền thống l&agrave; h&igrave;nh thức chơi xổ số c&oacute; nhiều giải tr&uacute;ng v&agrave; cơ cấu giải thưởng hấp dẫn, với giải đặc biệt l&ecirc;n đến 2 tỷ đồng n&ecirc;n được nhiều người chơi y&ecirc;u th&iacute;ch.</p>

    <p>Xổ số truyền thống miền Nam c&oacute; 21 tỉnh v&agrave; 22 đ&agrave;i (do Hồ Ch&iacute; Minh xổ 2 ng&agrave;y thứ hai v&agrave; thứ bảy) v&agrave; được quay số mở thưởng v&agrave;o 16h15 h&agrave;ng ng&agrave;y:</p>

    <table class="keno table-striped table-hover">
      <tbody>
        <tr>
          <th>Thứ</th>
          <th>Đ&agrave;i</th>
        </tr>
        <tr>
          <td>Thứ Hai</td>
          <td>TP. HCM - Đồng Th&aacute;p - C&agrave; Mau</td>
        </tr>
        <tr>
          <td>Thứ Ba</td>
          <td>Bến Tre - Vũng T&agrave;u - Bạc Li&ecirc;u</td>
        </tr>
        <tr>
          <td>Thứ Tư</td>
          <td>Đồng Nai - Cần Thơ - S&oacute;c Trăng</td>
        </tr>
        <tr>
          <td>Thứ Năm</td>
          <td>T&acirc;y Ninh - An Giang - B&igrave;nh Thuận</td>
        </tr>
        <tr>
          <td>Thứ S&aacute;u</td>
          <td>Vĩnh Long - B&igrave;nh Dương - Tr&agrave; Vinh</td>
        </tr>
        <tr>
          <td>Thứ Bảy</td>
          <td>TP. HCM - Long An - B&igrave;nh Phước - Hậu Giang</td>
        </tr>
        <tr>
          <td>Chủ Nhật</td>
          <td>Tiền Giang - Ki&ecirc;n Giang - Đ&agrave; Lạt</td>
        </tr>
      </tbody>
    </table>

    <h3 class="mt-4">Cơ cấu giải thưởng</h3>

    <p>C&aacute;ch d&ograve; số kiến thiết miền Nam</p>

    <section class="prizes">
    <table class="keno table-striped table-hover">
      <tbody>
        <tr>
          <th colspan="3">C&Aacute;CH D&Ograve; SỐ KIẾN THIẾT MIỀN NAM</th>
        </tr>
        <tr>
          <td>Giải đặc biệt</td>
          <td>Tr&ugrave;ng 6 số</td>
          <td>2.000.000.000đ</td>
        </tr>
        <tr>
          <td>Giải nhất</td>
          <td>Tr&ugrave;ng 5 số cuối</td>
          <td>30.000.000đ</td>
        </tr>
        <tr>
          <td>Giải nh&igrave;</td>
          <td>Tr&ugrave;ng 5 số cuối</td>
          <td>15.000.000đ</td>
        </tr>
        <tr>
          <td>Giải ba</td>
          <td>Tr&ugrave;ng 5 số cuối</td>
          <td>10.000.000đ</td>
        </tr>
        <tr>
          <td>Giải tư</td>
          <td>Tr&ugrave;ng 4 số cuối</td>
          <td>3.000.000đ</td>
        </tr>
        <tr>
          <td>Giải năm</td>
          <td>Tr&ugrave;ng 4 số cuối</td>
          <td>1.000.000đ</td>
        </tr>
        <tr>
          <td>Giải s&aacute;u</td>
          <td>Tr&ugrave;ng 4 số cuối</td>
          <td>400.000đ</td>
        </tr>
        <tr>
          <td>Giải bảy</td>
          <td>Tr&ugrave;ng 3 số cuối</td>
          <td>200.000đ</td>
        </tr>
        <tr>
          <td>Giải t&aacute;m</td>
          <td>Tr&ugrave;ng 2 số cuối</td>
          <td>100.000đ</td>
        </tr>
        <tr>
          <td>Giải an ủi</td>
          <td>Chỉ sai 1 chữ số ở h&agrave;ng trăm ng&agrave;n so với giải đặc biệt</td>
          <td>50.000.000đ</td>
        </tr>
        <tr>
          <td>Giải khuyến kh&iacute;ch</td>
          <td>Tr&ugrave;ng h&agrave;ng trăm ng&agrave;n, sai 1 chữ số ở bất cứ h&agrave;ng n&agrave;o của 5 chữ số c&ograve;n lại so với giải đặc biệt</td>
          <td>6.000.000đ</td>
        </tr>
      </tbody>
    </table>

    <div class="alert-warning mt-3">
    <h4>Lưu &yacute;</h4>
    Mỗi v&eacute; chỉ được nhận 1 phần thưởng cao nhất. Tr&uacute;ng Đặc Biệt + An ủi + KK kh&ocirc;ng thưởng</div>

    <h3>V&eacute; số cặp nguy&ecirc;n</h3>

    <p>V&eacute; số cặp nguy&ecirc;n l&agrave; 1 lốc v&eacute; chỉ kh&aacute;c nhau ở con số đầu ti&ecirc;n.</p>

    <p>V&iacute; dụ: cặp nguy&ecirc;n miền Nam c&oacute; 5 số cuối l&agrave; 12345, th&igrave; lần lượt sẽ c&oacute;:</p>

    <ul>
      <li><strong>0</strong>12345 (11 v&eacute;)</li>
      <li><strong>1</strong>12345 (11 v&eacute;)</li>
      <li><strong>2</strong>12345 (11 v&eacute;)</li>
      <li>...</li>
      <li><strong>9</strong>12345 (11 v&eacute;)</li>
    </ul>

    <p>Tổng cộng 110 v&eacute;</p>

    <h3 class="mt-4">Tỉ lệ tr&uacute;ng thưởng v&eacute; số cặp nguy&ecirc;n</h3>

    <p>Khi mua cặp nguy&ecirc;n số tiền qu&yacute; kh&aacute;ch bỏ ra kh&aacute; cao n&ecirc;n tỉ lệ tr&uacute;ng cũng được n&acirc;ng l&ecirc;n rất nhiều:</p>
    <strong>Thứ 1: Tr&uacute;ng an ủi (Giải đặc biệt phụ)</strong>

    <ul>
      <li>Tỷ lệ tr&uacute;ng giải phụ đặc biệt tương đối cao hơn rất nhiều nếu bạn mua cả theo cặp nguy&ecirc;n. Kết quả mở thưởng c&oacute; 6 chữ số XXXXXX, nếu bạn chỉ trật con số đầu ti&ecirc;n (h&agrave;ng trăm ngh&igrave;n) v&agrave; tr&uacute;ng đủ 5 số cuối c&ugrave;ng theo thứ tự của giải đặc biệt l&agrave; bạn đ&atilde; được tr&uacute;ng giải an ủi 50 triệu đồng.</li>
      <li>V&iacute; dụ: khi qu&yacute; kh&aacute;ch c&oacute; cặp nguy&ecirc;n miền Nam gồm 110 tờ tr&ugrave;ng 6 số của giải đặc biệt th&igrave; chắc chắn đ&atilde; l&atilde;nh 11 v&eacute; giải đặc biệt tương đương 22 tỷ, nhưng k&egrave;m theo đ&oacute; c&ograve;n c&oacute; 99 v&eacute; tr&uacute;ng giải an ủi 50 triệu đồng l&agrave; 4 tỷ 950 triệu đồng v&igrave; chỉ sai số đầu ti&ecirc;n. Tổng giải thưởng nhận được l&agrave; 26 tỷ 950 triệu đồng.</li>
    </ul>
    <strong>Thứ 2: Tr&uacute;ng giải khuyến kh&iacute;ch</strong>

    <ul>
      <li>L&agrave; giải khi trật 1 con số bất kỳ so với giải đặc biệt (kh&ocirc;ng t&iacute;nh con số đầu ti&ecirc;n v&igrave; khi đ&oacute; đ&atilde; tr&uacute;ng giải an ủi) th&igrave; qu&yacute; kh&aacute;ch đ&atilde; tr&uacute;ng giải thưởng 6 triệu đồng x 10 v&eacute;, tổng nhận được l&agrave; 60 triệu đồng.</li>
      <li>V&iacute; dụ: qu&yacute; kh&aacute;ch c&oacute; cặp số 123456, số đặc biệt l&agrave; 123457 th&igrave; qu&yacute; kh&aacute;ch đ&atilde; tr&uacute;ng giải tương đương 6 triệu đồng.</li>
    </ul>

    <div class="alert-warning mt-3">
    <h4>Lưu &yacute;</h4>
    Qu&yacute; kh&aacute;ch chỉ được nhận 1 phần thưởng cao nhất. Tr&uacute;ng Đặc Biệt + An ủi + KK kh&ocirc;ng thưởng.</div>
    </section>
    </div>

      `,
      content2: undefined,
      imageUrl: '',
      title: '',
      isLoading: false,
      activedFAQ: 'faq-0',
      isActiveGameRule: true
      // listQuestionFAQ: [
      //   {
      //     title:
      //       'Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào?',
      //     content:
      //       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   },
      //   {
      //     title: 'Đăng ký tài khoản trên TÀI LỘC như thế nào?',
      //     content:
      //       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   },
      //   {
      //     title: 'Đặt vé trên TÀI LỘC thì thanh toán thế nào?',
      //     content:
      //       'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   }
      // ]
    };
  },
  computed: {
    timeWaitingArticle: {
      get() {
        return this.$store.getters.getterTimeWaitingArticle;
      },
      set(newVal) {
        this.$store.commit('setTimeWaitingArticle', newVal);
      }
    },
    isTab() {
      return this.$route.meta.isTab;
    },
    listQuestionFAQ: {
      get() {
        return this.$store.getters.getterListQuestionFAQ;
      },
      set(newVal) {
        this.$store.commit('setListQuestionFAQ', newVal);
      }
    },
    isQuestionFAQ() {
      return this.listQuestionFAQ && this.listQuestionFAQ.length > 0;
    }
  },
  created() {
    if (this.$route.path != '/ho-tro/cau-hoi-thuong-gap') {
      this.getArticleBySlug();
    }
    if (!this.isQuestionFAQ) {
      this.getArticleByCategoryCauHoiThuongGap();
    }

    if (this.$route.query.tab === 'how-to-play') {
      this.isActiveGameRule = false;
    }
  },
  methods: {
    handlerUpdateFAQ(id) {
      this.activedFAQ = id;
    },
    getArticleByCategoryCauHoiThuongGap() {
      this.$store.dispatch('getArticleByCategorySlug', 'cau-hoi-thuong-gap').then((resp) => {
        this.listQuestionFAQ = resp;
      });
    },
    getArticleBySlug() {
      let slug = this.$route.path.split('/')[2];
      if (this.timeWaitingArticle) {
        clearTimeout(this.timeWaitingArticle);
      }
      this.timeWaitingArticle = setTimeout(() => {
        this.$store
          .dispatch('getArticleBySlug', slug)
          .then((resp) => {
            const { content2, title, image_url } = resp;
            this.$emit('updateTitleMobileArticleList', title);
            this.title = title;
            // this.content = content;
            this.content2 = content2;
            this.imageUrl = image_url || undefined;
          })
          .catch(() => {
            this.$router.push({ name: 'notFound' });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 1000);
    }
  }
};
</script>
