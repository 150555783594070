<template>
  <div :id="'faq-' + index" class="faq">
    <input type="checkbox" />
    <label @click="handlerShowItem('faq-' + index)">
      <p class="faq-heading">{{ data.title }}</p>
      <div class="faq-arrow-wrapper">
        <div class="faq-arrow"></div>
      </div>
      <div class="faq-text">
        <div v-if="data.content" class="fullDescription faq-des" v-html="data.content"></div>
        <div class="faq-des" v-else><p class="font-italic">Đang cập nhật</p></div>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: '',
          content: ''
        };
      }
    },
    index: {
      type: Number
    },
    activedFAQ: {
      type: String
    }
  },
  data() {
    return {
      show: false
    };
  },
  created() {},
  mounted() {
    if ('faq-' + this.index === this.activedFAQ) {
      document.getElementById('faq-' + this.index).classList.add('show');
    }
  },
  methods: {
    handlerShowItem(id) {
      let itemToggle = document.getElementById(id).classList[1];
      const items = document.getElementsByClassName('faq');
      for (var i = 0; i < items.length; i++) {
        if (items[i].classList[1]) {
          items[i].classList.remove('show');
        }
      }
      if (!itemToggle) {
        document.getElementById(id).classList.add('show');
      }

      this.$emit('handlerUpdateFAQ', id);
    }
  }
};
</script>
<style lang="scss" scoped>
label {
  margin-bottom: 0;
}
.faq-heading {
  color: #333333;
  font-weight: 600;
  padding: 0.75rem 2.5rem 0.75rem 1.25rem;
  position: relative;
  transition: 0.8s all;
  line-height: 1.5;
  // &:after {
  //   content: '';
  //   width: calc(100% - 40px);
  //   height: 1px;
  //   background-color: red;
  //   position: absolute;
  //   bottom: 0;
  //   left: 20px;
  // }
}

.faq-text {
  width: 95%;
  padding-left: 20px;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.faq {
  .faq-arrow-wrapper {
    float: right;
    position: absolute;
    top: 14px;
    right: 16px;
    width: 20px;
    height: 20px;
    z-index: 1;
    background-color: #fafafa;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .faq-arrow {
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
    border-left: 1.5px solid #00509d;
    border-bottom: 1.5px solid #00509d;
    border-top: none;
    border-right: none;
    position: relative;
    top: 2px;
  }
}
.show {
  .faq-text {
    max-height: 30rem;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
  .faq-arrow {
    border-left: none;
    border-bottom: none;
    border-top: 1.5px solid #00509d;
    border-right: 1.5px solid #00509d;
    top: -1px;
  }
  .faq-heading {
    &:after {
      content: '';
      width: calc(100% - 40px);
      height: 1px;
      background-color: #e8e8e8;
      position: absolute;
      bottom: 0;
      left: 20px;
    }
  }
}

.faq-des {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #666666;
}

.faq {
  margin: 0 auto;
  border-radius: 3px;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 10px;
  transition: 0.8s all;
}
.faq label {
  display: block;
  position: relative;
  // overflow: hidden;
  cursor: pointer;
  // height: 56px;
  // padding-top: 6px;
}

.faq input[type='checkbox'] {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}
</style>
